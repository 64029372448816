import React, { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Typography } from "@material-ui/core"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"

const NotFoundPage = () => {
  const [href, setHref] = useState()
  useEffect(() => {
    setHref(window.location.href)
  }, [])
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Box py={6} textAlign="center">
        <Box
          fontSize="7rem"
          children={<ErrorOutlineIcon fontSize="inherit" htmlColor="#ccc" />}
        />
        <Typography variant="h1">404</Typography>
        <Typography variant="h4">Not Found</Typography>
        <Typography variant="h6" color="textSecondary">
          We cannot find this page
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {href}
        </Typography>
      </Box>
    </Layout>
  )
}

export default NotFoundPage
